import { FormWithRedux } from '../../FormWithRedux';

export class ACCBISTMentalStateForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('alterationInMentalStateSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'lossOfConsciousnessDuration') {
            return this.getValue('lossOfConsciousness') == true;   
        }
        else if (identifier == 'retrogradeAmnesiaDuration') {
            return this.getValue('retrogradeAmnesia') == true;   
        } else if (identifier == 'lossOfConsciousnessDurationWarning') {
            return this.getValue('lossOfConsciousness') == true && this.getValue('lossOfConsciousnessDuration') != undefined && this.getValue('lossOfConsciousnessDuration') > 30;
        }

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if(identifier == 'retrogradeAmnesia' || identifier == 'alteredMentalState' || identifier == 'lossOfConsciousness') {
            return this.getValue('alterationInMentalState') == true;  
        }
        else if (identifier == 'lossOfConsciousnessDuration') {
            return this.getValue('lossOfConsciousness') == true;   
        }
        else if (identifier == 'retrogradeAmnesiaDuration') {
            return this.getValue('retrogradeAmnesia') == true;   
        } else if (identifier == 'lossOfConsciousnessDurationWarning') {
            return false;
        }

        return true;
    }

    getValueType(identifier) {
        if(identifier == 'lossOfConsciousnessDuration'
            || identifier == 'retrogradeAmnesiaDuration') {
            return 'double';
        }
        else if(identifier == 'lossOfConsciousness'
        || identifier == 'retrogradeAmnesia'
        || identifier == 'alteredMentalState') {
            return 'boolean';
        }

        return null;
    }

    submissionValidation() {
        return [];
    }

    editingValidation(identifier) {
        return [];
    }
}