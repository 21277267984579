import moment from 'moment';
import { BloodPressure } from '../Form To FormsUI/Subclasses/BloodPressureTextFormDetail';
import { TroponinResult } from '../../forms/edChestPain01/Custom Types/Troponin';
import { DateHelper } from '../Helpers/DateHelper';
import { QuidelOrthoTroponinResult } from '../../forms/edChestPain01/Custom Types/QuidelOrthoTroponin';
import { QuidelOrtho2TroponinResult } from '../../forms/edChestPain01/Custom Types/QuidelOrtho2Troponin';

class FormDetail {

    set initialValue(value) {
        this._initialValue = value;

        // If no editing value, no risk in replacing 'user entry'
        // if there is an editingValue and we would be replacing the user entry to make the initialValue displayed, update editingValue
        // Else there is an editing value that produces a currentValue, no risk or need to replace editingValue if initialValue == currentValue
        
        let previousValue = this.currentValue; // Because currentValue is based on the editingValue, which is not yet updated.
        if ((previousValue != null && previousValue != value) || this.editingValue == null) {
            this.editingValue = value;
        }
    }

    get initialValue() {
        return this._initialValue;
    }

    set title(value) {
        this._title = value;
    }

    set editingValue(value) {
        this._editingValue = value;
    }

    get editingValue() {
        return this._editingValue;
    }

    get currentValue() {
        if (typeof this.editingValue === this.form.getValueType(this.identifier)) {
            return this.editingValue;
        }

        return (this.doesValueConformToType(this.form.getValueType(this.identifier)));
    }

    get title() {
        return this._title;
    }

    get updateRendering() {
        return this._updateRendering;
    }

    set updateRendering(value) {
        this._updateRendering = value;
    }

    doesValueConformToType(type) {
        if (this.editingValue != null && this.editingValue != undefined) {
            if (type == 'string' && this.editingValue.toString() != null) {
                return this.editingValue.toString();
            } else if (type == 'boolean') {
                return this.editingValue;
            } else if (type == 'double' && isNaN(this.editingValue) == false) {
                return parseFloat(this.editingValue);
            } else if (type == 'date') {
                if (this.editingValue instanceof Date) {
                    return this.editingValue;
                } else if (typeof this.editingValue == 'string') {
                    let dateTimeValue = moment(this.editingValue);
                    if (dateTimeValue.toDate() != 'Invalid Date') {
                        return dateTimeValue.toDate();
                    } 
                }
            } else if (type == 'bloodpressure') {
                let bloodpressure = BloodPressure.bloodPressureFromString(this.editingValue);
                if (bloodpressure != null) {
                    return bloodpressure.string();
                }
            } else if (type == 'troponin') {
                let troponin = TroponinResult.newTroponinResult(this.editingValue.toString());
                if (troponin != null) {
                    return troponin.doubleValue;
                }
            } else if (type == 'qoTroponin') {
                let troponin = QuidelOrthoTroponinResult.newTroponinResult(this.editingValue.toString());
                if (troponin != null) {
                    return troponin.doubleValue;
                }
            } else if (type == 'qoTroponin2') {
                let troponin = QuidelOrtho2TroponinResult.newTroponinResult(this.editingValue.toString());
                if (troponin != null) {
                    return troponin.doubleValue;
                }
            } else if(type == 'dateOfBirth' && this.editingValue.age != null){
                return this.editingValue;
            }
        }
        return null;
    }

    constructor(form, identifier = null, title, detail = null, type, controlType = null, controlSubtype = null, options, subcellIDs, unitText = null, weblink = null, dateFormat = null, info = null, updateRendering = false) {
        this.form = form;
        this.identifier = identifier;
        this.title = title;
        this.detail = detail;
        this.canHaveValue = type == 'subform' || controlType == 'badge' || controlType == 'plain' || controlType == 'weblink' ? false : true;
        // this.editingValue;
        // this.currentValue;
        // this.initialValue;
        this.info = info;

        this.subcellIDs = subcellIDs;

        this.type = type;
        this.controlType = controlType;
        this.controlSubtype = controlSubtype;
        this.options = options;
        this.unitText = unitText;
        this.weblink = weblink;
        this.dateFormat = dateFormat;
        this.updateRendering = updateRendering;
    }

    resetToInitialValue() {
        this.editingValue = this.editingValue(this.initialValue);
    }

    editingStateChanged() {
        // Override for custom response.
    }

    valueDescription(returnTitleIfTrueForBool) {
        let type = this.form.getValueType(this.identifier);

        if (this.editingValue != null && this.editingValue != undefined) {
            if (type == 'string' && this.editingValue.toString() != null && this.editingValue.toString().length > 0) {
                if (this.options.length > 0) {
                    var result = this.options.find(obj => {
                        return obj.identifier === this.editingValue || obj.title === this.editingValue;
                    });
                    
                    return result.title;
                }
                return this.editingValue.toString();
            } else if (type == 'boolean') {
                if(this.editingValue == null){
                    return null;
                } else if (this.editingValue == false) {
                    return false;
                }

                return !returnTitleIfTrueForBool ? true : this.title;
                // return this.title.includes(',') ? '"' + this.title +  '"' : this.title;
            } else if (type == 'double' && isNaN(this.editingValue) == false) {
                return parseFloat(this.editingValue);
            } else if (type == 'date') {
                if (this.editingValue instanceof Date) {
                    return DateHelper.getDateAsToday(this.editingValue);
                } else if (typeof this.editingValue == 'string') {
                    let dateValue = moment(this.editingValue);
                    if (dateValue.toDate() instanceof Date) {
                        return DateHelper.getDateAsToday(dateValue.toDate());
                    }
                }
            } else if (type == 'bloodpressure') {
                let bloodpressure = BloodPressure.bloodPressureFromString(this.editingValue);
                if (bloodpressure != null) {
                    return bloodpressure.string();
                }
            } else if (type == 'troponin') {
                let troponin = TroponinResult.newTroponinResult(this.editingValue.toString());
                if (troponin != null) {
                    return troponin.string;
                }
            }
        }
        
        return null;
    }
}

export { FormDetail };