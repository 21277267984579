import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import { FormBadge } from '../../FormsUI/FormsUI Components/Controls/FormBadge';
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../../FormsUI/FormsUI Components/Controls/FormLabel';
import { FormValidationError } from '../../Form/Validation/FormValidationError';
import { DateHelper } from '../../Helpers/DateHelper';
import { FormPie } from '../../FormsUI/FormsUI Components/Controls/FormPie';

export class CalculationFormDetail extends React.Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount(){
        if (typeof this.props.formDetail.form.markCalculationAsObserved === 'function'){
            this.props.formDetail.form.markCalculationAsObserved(this.props.formDetail.identifier);
        }
    }

    render() {
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        let validation = [];
        let subcells = this.props.renderSubcells();


        let title = this.props.formDetail.title;

        let calculationError = this.props.formDetail.form.calculationError(this.props.formDetail.identifier);
        if(calculationError != null){
            validation.push(<FormLabel key={calculationError.message + this.props.formDetail.identifier} value={calculationError.message} textColor={calculationError.errorType == FormValidationError.FormErrorType.warning ? 'orange' : 'red'} />);
        }
       
        let bottomControl;
        if (typeof this.props.formDetail.form.latestCalculation === 'function') { 
            let newestCalculation = this.props.formDetail.form.latestCalculation(this.props.formDetail.identifier);
            if(newestCalculation != null && !newestCalculation.observed){
                let previousCalculation = this.props.formDetail.form.previousCalculation(this.props.formDetail.identifier);
                if(previousCalculation != null){
                    var previousModelString = 'Previous result: ' +  previousCalculation.valueString + ' (' + DateHelper.relativeDate(previousCalculation.lastModifiedDate) + ')';
                    bottomControl =
                                    <div>
                                        <FormLabel key={previousModelString + this.props.formDetail.identifier} value={previousModelString} textColor={'#B0B0B0'}/>
                                    </div>;
                } else {
                    bottomControl =
                                    <div>
                                        <FormLabel key={'new' + this.props.formDetail.identifier} value={'New'} textColor={FormsStyles.primaryTint}/>
                                    </div>;
                }
            }
        }

        let formPie;
        let badgeDetails = this.props.formDetail.form.getBadgeValue(this.props.formDetail.identifier);
        let badge;
        if(typeof this.props.formDetail.form.calculationProgress === 'function' && calculationError == null){
            let calculationProgress = this.props.formDetail.form.calculationProgress(this.props.formDetail.identifier);
            if(calculationProgress != null && calculationProgress != 1){
                if(calculationProgress != 0){
                    formPie = <FormPie totalValue={1} filledValue={calculationProgress}/>;
                }
                validation.push(<FormLabel key={'More pathway details are required' + this.props.formDetail.identifier} value={'More pathway details are required'} textColor={'orange'} fontSize={0.9} />);
            }
            if (badgeDetails != null && badgeDetails[0] != null) {
                badge = <FormBadge value={badgeDetails[0].value} color={badgeDetails[0].color} solid={badgeDetails[0].solid} />;
                if (badgeDetails.title != null) {
                    title = badgeDetails[0].title;
                }
            }
    
        } else if (badgeDetails != null && badgeDetails[0] != null) {
            badge = <FormBadge value={badgeDetails[0].value} color={badgeDetails[0].color} solid={badgeDetails[0].solid} />;
            if (badgeDetails.title != null) {
                title = badgeDetails[0].title;
            }
        }

        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} topLeftControl={formPie} titleString={title} detailString={this.props.formDetail.detail} isMandatory={isMandatory} validation={validation} topRightControl={badge} subcell={subcells} subCellbackgroundColor={FormsStyles.backgroundColor} bottomControl={bottomControl} showHover={true}/>
        );
    }
}

CalculationFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};