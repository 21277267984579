import {
    EXPORT_TO_HCS_PENDING,
    EXPORT_TO_HCS_FULFILLED,
    EXPORT_TO_HCS_FAILED,
    RESET_EXPORT_STATE
} from '../actions/ExportToHCSActions';

// INITIALIZE STATE
const initialState = {
    exportToHCSPending: false,
    exportToHCSFulfilled: false,
    exportToHCSFailed: false
};

export const ExportToHCSReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXPORT_TO_HCS_PENDING: {
            return {
                ...state,
                exportToHCSPending: true,
                exportToHCSFulfilled: false,
                exportToHCSFailed: false
            };
        }
        case EXPORT_TO_HCS_FULFILLED: {
            return {
                ...state,
                exportToHCSPending: false,
                exportToHCSFulfilled: true,
                exportToHCSFailed: false
            };
        }
        case EXPORT_TO_HCS_FAILED: {
            return {
                ...state,
                exportToHCSPending: false,
                exportToHCSFulfilled: false,
                exportToHCSFailed: true
            };
        }
        case RESET_EXPORT_STATE: {
            return {
                ...state,
                exportToHCSPending: false,
                exportToHCSFulfilled: false,
                exportToHCSFailed: false
            };
        }
        default:
            return state;
    }
};