import { FormWithRedux } from '../../FormWithRedux';
import { ACCBISTOrientationCheck1Form } from './ACCBIST-OrientationCheck1Form';
import { ACCBISTPictureCardRecollectionTestForm } from './ACCBIST-PictureCardRecollectionTestForm';
import { ACCBISTNeuroObsForm } from './ACCBIST-NeuroObsForm';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';
import { FormsStyles, FormsUIGlossary } from '../../../waferJS/FormsUI/FormsUI';
import moment from 'moment';

export class ACCBISTAWPTAForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('awptaCheck1Subform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'orientationCheck1Subform':
                return new ACCBISTOrientationCheck1Form(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'pictureCardTestRecollectionSubform':
                return new ACCBISTPictureCardRecollectionTestForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'gcsSubform1':
                return new ACCBISTNeuroObsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater, "1");
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'awptaWarning') {
            return this.getValue('cognitiveImpairment') == true;
        }

        return true;
    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'awptaWarning') {
            return false;
        }
        
        return true;
    }

    getValueType(identifier) {
        return 'date';
    }

    submissionValidation(identifier) {
        return [];
    }

    editingValidation(identifier) {
        let self = this;
        if (identifier == 'awptaCheck1DateTime') {
            let value = self.getValue(identifier);
            let check1Value = self.getValue('injuryOccuranceDateTime');
            if (value != null && value != undefined && check1Value != null && check1Value != undefined) {
                let firstCheck = moment(new Date(check1Value));
                let secondCheck = moment(new Date(value));
                var duration = moment.duration(secondCheck.diff(firstCheck));
                var hours = duration.asHours();

                if (hours > 24) {
                    let valueConflict = new FormValidation('Over24HoursValidation', function () {
                        return [new FormValidationError(FormValidationError.FormErrorType.warning, 'The Abbreviated Westmead PTA Scale is not validated for use after 24 hours. Consider using a standard PTA assessment tool.')];
                    });

                    return [valueConflict];
                }
                else {
                    return [];
                }
            }
            else {
                return [];
            }
        }

        return [];
    }

    detailForIdentifier(prefix, value) {
        if(value.identifier == 'gcsSubform1') {
            return null;
        }
        
        return super.detailForIdentifier(prefix, value);
    }

    htmlForIdentifier(prefix, value) {
        if(value.identifier == 'gcsSubform1') {
            return null;
        }
        else {
            return super.htmlForIdentifier(prefix, value);
        }
    }
}