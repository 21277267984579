// IMPORT PACKAGE REFERENCES
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PathwayReferenceHelper } from '../../forms/PathwayReferenceHelper';
import { FormButton } from '../../waferJS/FormsUI/FormsUI Components/Controls/FormButton';
import { clearResultsAlert, postResultsBadge, clearResultsBadge } from '../state/actions/NotificationsActions';
import { Toast } from '../Modals/Toast';
import { PrintHeader } from '../shared/PrintHeader';
import { Chevron } from '../shared/Chevron';
import { FormValidationError } from '../../waferJS/Form/Validation/FormValidationError';
import { FormLabel } from '../../waferJS/FormsUI/FormsUI Components/Controls/FormLabel';
import { ReferenceDataLoader } from '../../forms/ReferenceDataLoader';
import { snapshotObservedActions } from '../state/actions/ActionActions';
import { snapshotObservedCalculations } from '../state/actions/CalculationActions';
import { CalculationModel } from '../../forms/edChestPain01/Custom Types/Calculation';
import { EDChestPainCalculationHelper } from '../../forms/edChestPain01/Helpers/EDChestPainCalculationHelper';
import { FormBadge } from '../../waferJS/FormsUI/FormsUI Components/Controls/FormBadge';
import { FormDetailWithReact } from '../../waferJS/Form To FormsUI/FormDetailWithReact';
import { ReferralOverlay } from '../pages/Overlays/ReferralOverlay';
import { withRouter } from '../routers/withRouter';

// COMPONENT
class FormPage extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            hasLoaded: false,
            showToast: null,
            errorLabels: [],
            hasError: false,
            subform: props.formLoader.form(this.props.match.params.pathwayId, this.props.match.params.formId),
            rawData: this.props.workflows[this.props.match.params.pathwayId].rawData,
            referralVisible: false
        };

        this.pushToNextPage = this.pushToNextPage.bind(this);
        this.pushToPreviousPage = this.pushToPreviousPage.bind(this);
        this.removeToast = this.removeToast.bind(this);
        this.pushToResults = this.pushToResults.bind(this);
        this.toastTimeout = null;
        this.resetFocus = this.resetFocus.bind(this);
        this.renderFormErrors = this.renderFormErrors.bind(this);
        this.toggleReferralOverlay = this.toggleReferralOverlay.bind(this);
        this.checkForOverlayState = this.checkForOverlayState.bind(this);
    }

    componentDidMount() {
        if (this.props.newResultsAvailable) {
            this.setState({ showToast: true });
        }

        this.resetFocus();
        this.setState({ subform: this.props.formLoader.form(this.props.match.params.pathwayId, this.props.match.params.formId) }, this.updateWindowTitle);
        this.checkForOverlayState();
    }

    componentWillUnmount() {
        this.props.clearResultsAlert(this.props.match.params.pathwayId);
        this.resetFocus();
    }

    componentDidUpdate(prevProps) {
        this.checkForOverlayState();

        if (JSON.stringify(this.props.match) !== JSON.stringify(prevProps.match)) {
            let subform = this.props.formLoader.form(this.props.match.params.pathwayId, this.props.match.params.formId);
            this.setState({ subform: subform }, this.updateWindowTitle);
            document.getElementById('form-container').focus();

            if (this.props.match.params.formId === 'resultsForm') {
                // window.gtag('event', 'viewed-insights-page', {
                //     event_category: 'action',
                //     event_label: 'viewed-insights-page'
                // });

                if (this.state.showToast) {
                    this.props.clearResultsAlert(this.props.match.params.pathwayId);
                }
                if (this.state.showToast != null) {
                    this.setState({ showToast: null });
                }

                let actions = this.props.actions[this.props.match.params.pathwayId];
                if (actions != null && Array.isArray(actions)) {
                    this.props.snapshotObservedActions(this.props.match.params.pathwayId, actions);
                }
                
                if(this.state.subform.parent != null){
                    this.state.subform.parent.dependentValuesUpdater.logObservedValues(this.props.match.params.pathwayId, this.props.workflows[this.props.match.params.pathwayId], this.state.subform);
                } else {
                    this.state.subform.dependentValuesUpdater.logObservedValues(this.props.match.params.pathwayId, this.props.workflows[this.props.match.params.pathwayId], this.state.subform);
                }
                
                let calculations = this.props.calculations[this.props.match.params.pathwayId];
                if (calculations != null) {
                    let visibleCalculations = {};
                    for (let calculationTypeKey of Object.keys(CalculationModel.CalculationType)) {
                        let calculationKey = CalculationModel.CalculationType[calculationTypeKey];
                        let latestCalculation = EDChestPainCalculationHelper.getLatestCalculation(calculationKey, calculations);
                        if (latestCalculation != null) {
                            visibleCalculations[latestCalculation.uuid] = latestCalculation;
                        }
                    }
                    this.props.snapshotObservedCalculations(this.props.match.params.pathwayId, visibleCalculations);
                }
            }
        } else if(JSON.stringify(this.props.workflows[this.props.match.params.pathwayId].rawData) !== JSON.stringify(prevProps.workflows[this.props.match.params.pathwayId].rawData)){
            let subform = this.props.formLoader.form(this.props.match.params.pathwayId, this.props.match.params.formId);
            this.setState({ subform: subform, rawData: this.props.workflows[this.props.match.params.pathwayId].rawData}, this.updateWindowTitle);
        }

        if (!this.state.hasLoaded) {
            if (window.document.scrollingElement != null) {
                window.document.scrollingElement.scrollTop = 0;
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.setState({ hasLoaded: true });
        }

        if (prevProps.newResultsAvailable != this.props.newResultsAvailable) {
            this.setState({ showToast: this.props.newResultsAvailable });
        }

        this.renderFormErrors();
    }

    
    updateWindowTitle() {
        document.title = 'Wayfind | ' + PathwayReferenceHelper.reference('title', ReferenceDataLoader.referenceData(this.props.match.params.pathwayId)) + ' - ' + this.state.subform.title;
    }

    removeToast() {
        this.props.clearResultsAlert(this.props.match.params.pathwayId);
    }

    checkForOverlayState() {
        if(this.props.expandedCells[this.props.match.params.pathwayId] != null){
            if(this.props.expandedCells[this.props.match.params.pathwayId].includes('referralOverlay') && !this.state.referralVisible){
                this.setState({referralVisible: true});
            } else if(!this.props.expandedCells[this.props.match.params.pathwayId].includes('referralOverlay') && this.state.referralVisible){
                this.setState({referralVisible: false});
            }
        } else if (this.state.referralVisible){
            this.setState({referralVisible: false});
        }
    }

    toggleReferralOverlay() {
        this.setState({referralVisible: !this.state.referralVisible});
    }

    pushToNextPage() {
        this.setState({ hasLoaded: false });

        this.props.navigate('/' + this.props.match.params.pathwayId + '/' + this.props.formLoader.nextFormDetails(this.props.match.params.pathwayId, this.props.match.params.formId).link);
        this.resetFocus();

        // window.gtag('event', 'pushed-to-next-page', {
        //     event_category: 'action',
        //     event_label: 'pushed-to-next-page'
        // });
    }

    pushToPreviousPage() {
        this.setState({ hasLoaded: false });

        this.props.navigate('/' + this.props.match.params.pathwayId + '/' + this.props.formLoader.previousFormDetails(this.props.match.params.pathwayId, this.props.match.params.formId).link);
        this.resetFocus();

        // window.gtag('event', 'pushed-to-previous-page', {
        //     event_category: 'action',
        //     event_label: 'pushed-to-previous-page'
        // });
    }

    pushToResults() {
        this.setState({ showToast: false });
        this.props.navigate('/' + this.props.match.params.pathwayId + '/resultsForm' + this.props.location.search);
        this.props.clearResultsAlert(this.props.match.params.pathwayId);
        this.props.clearResultsBadge(this.props.match.params.pathwayId);
        if (window.document.scrollingElement != null) {
            window.document.scrollingElement.scrollTop = 0;
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.resetFocus();

        // window.gtag('event', 'clicked-insights-pop-up', {
        //     event_category: 'action',
        //     event_label: 'clicked-insights-pop-up'
        // });
    }

    resetFocus() {
        // Remove focus from any focused element
        if (document.activeElement != null) {
            document.activeElement.blur();
        }
    }

    renderFormErrors() {
        let form = this.props.formLoader.mainForm(this.props.match.params.pathwayId);
        let errors = form.validationErrors(this.state.subform.formIdentifier, null);
        let validationsToShow = errors.filter(function (error) {
            return error != null && error.errorType != FormValidationError.FormErrorType.required;
        });

        let errorLabels = [];
        let hasError = false;
        for (let index in validationsToShow) {
            let error = validationsToShow[index];

            if (error.errorType == FormValidationError.FormErrorType.error) {
                hasError = true;
            }

            errorLabels.push(<FormLabel key={index.toString() + error.message} value={error.message} textColor={error.color} fontSize={0.9} />);
        }

        if (this.state.hasError != hasError) {
            this.setState({ hasError: hasError });
        }

        if (this.state.errorLabels != null && this.state.errorLabels.toString() != errorLabels.toString()) {
            this.setState({ errorLabels: errorLabels });
        } else if (this.state.errorLabels == null || this.state.errorLabels == undefined) {
            this.setState({ errorLabels: errorLabels });
        }
    }

    render() {
        let badges;
        if(this.state.subform.formIdentifier !== 'resultsForm' && this.state.subform.formIdentifier !== 'notesForm' && this.state.subform.formIdentifier !== 'mainForm'){
            let badgeDetails = this.props.formLoader.mainForm(this.props.match.params.pathwayId).getBadgeValue(this.state.subform.formIdentifier);
            
            if(badgeDetails != null && badgeDetails.length > 0 ){
                badges = <div className="form-page-badge-container">{badgeDetails.map((badgeDetail) => {
                    return <FormBadge key={badgeDetail.value} value={badgeDetail.value} color={badgeDetail.color} solid={badgeDetail.solid} />;
                })}</div>;
            }
        }
        
        return (
            <div ref={node => this.node = node} className="form-page-container">

                {this.state.referralVisible && 
                    <ReferralOverlay 
                        toggleOverlay={this.toggleReferralOverlay} pathwayId={this.props.match.params.pathwayId} data={this.state.rawData} 
                        pathwayWorkflow={this.props.workflows[this.props.match.params.pathwayId]} subform={this.state.subform}
                        initialUserName={this.props.userName} initialUserEmail={this.props.userEmail}
                        />}

                {!this.props.isExtension && <PrintHeader forceVisibility={false} pathwayId={this.props.match.params.pathwayId} />}
                <header className={'page-title ' + (this.state.subform.detail == null && this.state.errorLabels.length == 0 ? 'no-descrption' : '')}>{this.state.subform.title}{badges}</header>
                {this.state.subform.formDetailString() != null && <div className={'page-description ' + (this.state.errorLabels.length == 0 ? 'no-validation' : '')}>{this.state.subform.formDetailString()}</div>}
                {this.state.errorLabels.length > 0 && <div className="form-header-validation">
                    {this.state.errorLabels.map((error) => { return error; })}
                </div>}
                {this.state.subform.sectionIds != undefined &&
                    <div className={'form-container ' + (this.props.isExtension ? 'hidden-nav' : '')} tabIndex={-1} id={'form-container'}>
                        {this.state.subform.sectionIds.map((sectionID) => {
                            let section = this.state.subform.sectionReference(sectionID);
                            
                            let sectionStyle = section[PathwayReferenceHelper.Type.sectionStyle];
                            var shouldDisplayChildForID = {};
                            section[PathwayReferenceHelper.Type.sectionChildIDs].forEach(childID => 
                                shouldDisplayChildForID[childID] = this.state.subform.shouldDisplayFormDetailForIdentifier(childID)
                            );

                            let shouldDisplaySection = Object.keys(shouldDisplayChildForID).map(identifier =>{
                                return shouldDisplayChildForID[identifier];
                            }).includes(true);
                            let isInlineSectionStyle = (sectionStyle == 'inline') && shouldDisplaySection;
                            
                            return (
                                <div key={sectionID} className={isInlineSectionStyle ? 'form-section-inline' : 'form-section'}>
                                    {shouldDisplaySection && <div className='section-title'>{section.title}</div>}
                                    {shouldDisplaySection && <div className='section-description'>{section.detail}</div>}
                                    {
                                        Object.keys(shouldDisplayChildForID).map((childID) => {
                                            let shouldDisplayChild = shouldDisplayChildForID[childID];
                                            if (shouldDisplayChild) {
                                                let formDetail = this.state.subform.formDetail(childID);
                                                return  <FormDetailWithReact formLoader={this.props.formLoader} key={childID} rawValue={this.state.rawData} formDetail={formDetail} isSummary={this.props.formLoader.shouldSummarize(this.props.match.params.pathwayId, this.props.match.params.formId)} isSubcell={false} showHover={true}/>;
                                            }
                                        })
                                    }
                                </div>
                            );
                        })}
                        <div className='navigation-buttons-container'>
                        {this.props.formLoader.previousFormDetails(this.props.match.params.pathwayId, this.props.match.params.formId) != null && !this.props.isExtension &&
                              <div className="previous-page-button">
                                  <FormButton textAlignment={'right'} image={<Chevron angle={'rotate(90deg)'} />} ignoreTab={false} onPress={this.pushToPreviousPage} activeBackgroundColor={'transparent'} backgroundColor={'transparent'} enabled={true} isSelected={false} leftToRight={true} title={this.props.formLoader.previousFormDetails(this.props.match.params.pathwayId, this.props.match.params.formId).title} />
                              </div>
                          }
                          {this.props.formLoader.nextFormDetails(this.props.match.params.pathwayId, this.props.match.params.formId) != null && !this.props.isExtension &&
                              <div className="next-page-button">
                                  <FormButton 
                                    textAlignment={'right'} 
                                    image={<Chevron angle={'rotate(-90deg)'} />} 
                                    ignoreTab={false} 
                                    onPress={this.pushToNextPage} 
                                    activeBackgroundColor={'transparent'}
                                    backgroundColor={'transparent'} 
                                    enabled={true} 
                                    isSelected={false} 
                                    title={this.props.formLoader.nextFormDetails(this.props.match.params.pathwayId, this.props.match.params.formId).title} 
                                  />
                              </div>
                          }

                          
                        </div>
                    </div>
                }

                {this.state.showToast != null && this.props.match.params.formId !== 'resultsForm' &&
                    <Toast changes={this.props.changes} pathwayId={this.props.match.params.pathwayId} showToast={this.props.newResultsAvailable} docked={this.props.collapseHeader} toastClicked={this.pushToResults} hide={this.removeToast} />
                }
            </div>
        );
    }
}

FormPage.propTypes = {
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    workflows: PropTypes.object.isRequired,
    updatingWorkflowPending: PropTypes.bool.isRequired,
    updatingWorkflowFulfilled: PropTypes.bool.isRequired,
    updatingWorkflowFailed: PropTypes.bool.isRequired,
    nextPage: PropTypes.object,
    calculations: PropTypes.object.isRequired,
    changes: PropTypes.object.isRequired,
    clearResultsAlert: PropTypes.func.isRequired,
    newResultsAvailable: PropTypes.bool.isRequired,
    isExtension: PropTypes.bool.isRequired,
    postResultsBadge: PropTypes.func.isRequired,
    clearResultsBadge: PropTypes.func.isRequired,
    collapseHeader: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    snapshotObservedActions: PropTypes.func.isRequired,
    snapshotObservedCalculations: PropTypes.func.isRequired,
    expandedCells: PropTypes.object.isRequired,
    formLoader: PropTypes.object.isRequired,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed } = state.workflowReducer;
    const { calculations } = state.calculationReducer;
    const { changes, newResultsAvailable } = state.notificationsReducer;
    const { actions } = state.actionsReducer;
    const { expandedCells } = state.subformStateReducer;
    return { workflows, updatingWorkflowPending, updatingWorkflowFulfilled, updatingWorkflowFailed, calculations, changes, newResultsAvailable, actions, expandedCells };
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ clearResultsAlert, postResultsBadge, clearResultsBadge, snapshotObservedActions, snapshotObservedCalculations }, dispatch)
);

const hoc = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormPage));

// EXPORT COMPONENT
export { hoc as FormPage };