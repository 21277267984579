import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';
import { HorizontalButtonControl } from '../../FormsUI/FormsUI Components/Controls/HorizontalButtonControl';
import { FormsStyles, FormsContentType } from '../../FormsUI/FormsUI';
import { PathwayReferenceHelper } from '../../../forms/PathwayReferenceHelper';
import { addExpandedCell, removeExpandedCell } from '../../../components/state/actions/SubformStateActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import React from 'react';
import PropTypes from 'prop-types';

class ExplicitSelectionFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            buttons: null,
            value: this.props.formDetail.editingValue
        };

        this.renderButtons = this.renderButtons.bind(this);
        this.yesPressed = this.yesPressed.bind(this);
        this.noPressed = this.noPressed.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    componentDidMount(){
        document.addEventListener('keypress', this.handleKeyPress);
        this.props.renderErrors();
        this.renderButtons();
    }

    componentWillUnmount(){
        document.removeEventListener('keypress', this.handleKeyPress);
    }

    componentDidUpdate(){
        if(this.props.formDetail.editingValue != this.state.value){
            this.setState({value: this.props.formDetail.editingValue}, this.renderButtons);
        }
        this.props.renderErrors();
    }
    
    handleKeyPress(e){
        if(document.activeElement == this.focusNode){
            // window.gtag('event', 'used-keyboard-input-for-checkbox', {
            //     event_category: 'action',
            //     event_label: 'used-keyboard-input-for-checkbox'
            // });

            let keyChar = String.fromCharCode(e.which);
            
            if(keyChar == 'y' || keyChar == 1){
                this.yesPressed();
            } else if(keyChar == 'n' || keyChar == 2){
                this.noPressed();
            } else if (e.which == 13 || e.which == 39){ //enter key: 13, right key: 39
                if(this.props.formDetail.editingValue == true){
                    this.noPressed();
                } else if(this.props.formDetail.editingValue == false){
                    this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, null);
                } else {
                    this.yesPressed();
                }
            } else if(e.which == 37){ //left key
                if(this.props.formDetail.editingValue == true){
                    this.yesPressed();
                } else if(this.props.formDetail.editingValue == false){
                    this.yesPressed();
                } else {
                    this.noPressed();
                }
            } 
        }
    }

    yesPressed() {
        let updatedOption = this.props.formDetail.editingValue == true ? null : true;
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, updatedOption, true);
        this.props.renderErrors();

        if(updatedOption == true && this.props.formDetail.subcellIDs.length > 0){
            let subcellIDs = this.props.formDetail.subcellIDs;
            subcellIDs.forEach( subCellID => {
                let subCell = PathwayReferenceHelper.reference(subCellID, this.props.formDetail.form.pathwayReferenceData);
                if (subCell[PathwayReferenceHelper.Type.itemType] == FormsContentType.ItemType.subform && !(subCell[PathwayReferenceHelper.Type.subformType] == FormsContentType.SubformType.suggestive)) {
                    this.props.addExpandedCell(this.props.formDetail.form.pathwayId, subCellID, this.props.expandedCells);
                }
            });
        }

        // window.gtag('event', 'checkbox-option-selected', {
        //     event_category: 'action',
        //     event_label: 'checkbox-option-selected'
        // });
    }

    noPressed() {
        let updatedOption = this.props.formDetail.editingValue == false ? null : false;
        this.props.formDetail.form.valueUpdated(this.props.formDetail.identifier, updatedOption, true);
        this.props.renderErrors();

        if(updatedOption == false && this.props.formDetail.subcellIDs.length > 0){
            let subcellIDs = this.props.formDetail.subcellIDs;
            let subCells = [];
            subcellIDs.forEach( subCellID => {
                let subCell = PathwayReferenceHelper.reference(subCellID, this.props.formDetail.form.pathwayReferenceData);
                if (subCell[PathwayReferenceHelper.Type.itemType] == FormsContentType.ItemType.subform && !(subCell[PathwayReferenceHelper.Type.subformType] == FormsContentType.SubformType.suggestive)) {
                    subCells.push(subCellID);
                }
            });
            if (subCells.length > 0) {
                this.props.removeExpandedCell(this.props.formDetail.form.pathwayId, subCells, this.props.expandedCells);
            }
        }

        // window.gtag('event', 'checkbox-option-selected', {
        //     event_category: 'action',
        //     event_label: 'checkbox-option-selected'
        // });
    }

    renderButtons() {
        let enabled = this.props.formDetail.form.isEnabled(this.props.formDetail.identifier);
        let buttons = [
            <FormButton key='yes' onPress={this.yesPressed} enabled={enabled} isSelected={this.state.value == true} title={'Yes'} ignoreTab={true}/>,
            <FormButton key='no' onPress={this.noPressed} enabled={enabled} title={'No'} isSelected={this.state.value == false} ignoreTab={true}/>
        ];

        this.setState({buttons: <HorizontalButtonControl buttons={buttons} />});
    }

    render() {
        let subcells = this.props.renderSubcells();
        let isMandatory = this.props.formDetail.form.isMandatory(this.props.formDetail.identifier);
        
        return (
            <FormContentView identifier={this.props.formDetail.identifier} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} info={this.props.formDetail.info} isMandatory={isMandatory} detailString={this.props.formDetail.detail} subCellbackgroundColor={FormsStyles.backgroundColor} validation={this.props.errorLabels} rightControl={this.state.buttons} subcell={subcells} showHover={true} allowFocus={true} focusRef={focusNode => this.focusNode = focusNode} />
        );
    }
}

ExplicitSelectionFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    addExpandedCell: PropTypes.func.isRequired, 
    removeExpandedCell: PropTypes.func.isRequired,
    expandedCells: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired
};

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { expandedCells } = state.subformStateReducer;
    return { expandedCells};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ addExpandedCell, removeExpandedCell }, dispatch)
);

const hoc = connect(mapStateToProps, mapDispatchToProps)(ExplicitSelectionFormDetail);

// EXPORT COMPONENT
export { hoc as ExplicitSelectionFormDetail };