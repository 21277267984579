import { FormContentView } from '../../FormsUI/FormsUI Components/Containers/FormContentView';
import { FormsStyles } from '../../FormsUI/FormsUI';
import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from '../../FormsUI/FormsUI Components/Controls/FormButton';
import { addExpandedCell, removeExpandedCell } from '../../../components/state/actions/SubformStateActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ACCReferralSubmissionFormDetail extends React.Component {

    constructor(props) {
        super(props);

        this.toggleReferallVisibility = this.toggleReferallVisibility.bind(this);
        this.renderButtons = this.renderButtons.bind(this);
    }

    toggleReferallVisibility() {
        let expansionState = this.props.expandedCells[this.props.formDetail.form.pathwayId];
        
        if(expansionState.includes('referralOverlay')) {
            this.props.removeExpandedCell(this.props.formDetail.form.pathwayId, 'referralOverlay', this.props.expandedCells);
        }
        else {
            this.props.addExpandedCell(this.props.formDetail.form.pathwayId, 'referralOverlay', this.props.expandedCells);
        }
    }

    renderButtons() {
        return (<FormButton key='Submit' onPress={this.toggleReferallVisibility} enabled={true} isSelected={false} title={'Submit'} ignoreTab={true}/>);
    }

    concussionReferralWarning() {
        let warningMessage = undefined;

        // Check if Injury Detail form completed
        let injuryDetailCompleted = false;
        const injuryDetailForm = this.props.formLoader.form(this.props.formDetail.form.pathwayId, 'injuryDetailsSubform');
        const injuryDetailFormProgress = injuryDetailForm.completionProgress();
        if (injuryDetailFormProgress) {
            injuryDetailCompleted = (injuryDetailFormProgress.current / injuryDetailFormProgress.total) == 1;
        }

        if (injuryDetailCompleted) {
            // Check if concussion is confirmed
            const concussionConfirmed = ((this.props.rawValue['concussiveForce'] == true || this.props.rawValue['dangerousMechanismOfOInjury'] == true) && this.props.rawValue['alterationInMentalState'] == true);
            if (!concussionConfirmed) {
                // If Injury Detail form completed and a concussion is NOT confirmed, display warning in Referral section.
                warningMessage = "Referral is not recommended when concussion is not confirmed";
            }
        }
        
        return warningMessage;
    }
    
    render() {
        let validation = [];

        return (
            <div style={{paddingBottom: this.props.isSubcell ? '0' : '0.6em'}}>
                <FormContentView identifier={this.props.formDetail.identifier} showHover={true} isSubcell={this.props.isSubcell} titleString={this.props.formDetail.title} detailString={this.props.formDetail.detail} topRightControl={this.renderButtons()} subcell={[]} validation={validation} subCellbackgroundColor={FormsStyles.backgroundColor} warningString={this.concussionReferralWarning()} />
            </div>
        );
    }
    
}

// CONFIGURE REACT REDUX
const mapStateToProps = state => {
    const { expandedCells } = state.subformStateReducer;
    return { expandedCells};
};

const mapDispatchToProps = dispatch => (
    bindActionCreators({ addExpandedCell, removeExpandedCell }, dispatch)
);

const hoc =connect(mapStateToProps, mapDispatchToProps)(ACCReferralSubmissionFormDetail);

// EXPORT COMPONENT
export { hoc as ACCReferralSubmissionFormDetail };

ACCReferralSubmissionFormDetail.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    renderErrors: PropTypes.func.isRequired,
    renderSubcells: PropTypes.func.isRequired,
    errorLabels: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired,
    addExpandedCell: PropTypes.func.isRequired, 
    removeExpandedCell: PropTypes.func.isRequired,
    expandedCells: PropTypes.object.isRequired,
    formLoader: PropTypes.object.isRequired,
    rawValue: PropTypes.object.isRequired
};