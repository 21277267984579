import React from "react";
import { FormLoader } from "../../forms/FormLoader";
import { useUserInfoContext } from "../Hooks/UserInfoProvider";
import { FormPage } from "./FormPage";

interface FormPageWrapperProp {
  formLoader: FormLoader;
  isExtension: boolean
}

export const FormPageWrapper: React.FC<FormPageWrapperProp> = ({ formLoader,isExtension  }) => {
  const { userInfo } = useUserInfoContext();
  return (
    <FormPage
      formLoader={formLoader}
      collapseHeader={true}
      isExtension={isExtension}
      userName={userInfo?.Name}
      userEmail={userInfo?.Email}
    />
  );
};
