import { saveWorkflow } from '../../../components//state/actions/WorkflowResponseActions';
import { redux } from '../../../App';
import { checkAndUpdateForAction } from '../../../components/state/actions/ActionActions';
import { ActionModel } from '../../Shared Custom Types/ActionModel';

export class ACCBISTActions {
    update(pathwayId, workflow, oldActions, form) {
        let data = workflow.rawData;

        let mainForm = form.parent != null ? form.parent : form;

        let progress = mainForm.completionProgress([]);
        let formComplete = (progress.current / progress.total) == 1;
        formComplete = true;

        if (!formComplete) {
            return [];
        }

        let requiredActions = [];

        let patientEligable = true;

        let scaleScore = data['calculatedSymptomScore'];

        var isUnder24Hours = false;
        var over4hoursSinceInjury = false;
        let injuryDateString = data['injuryOccuranceDateTime'];

        if (injuryDateString != undefined && injuryDateString != null) {
            let injuryDate = new Date(injuryDateString);
            let now = new Date();
            let msBetweenDates = Math.abs(injuryDate.getTime() - now.getTime());
            let hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            isUnder24Hours = hoursBetweenDates <= 24;
            over4hoursSinceInjury = hoursBetweenDates >= 4;
        }

        //seekNeuroscienceReview
        var seekNeuroReview = false;
        
        if(data['isCTScanNormal'] != null && data['isCTScanNormal'] != undefined && data['isCTScanNormal'] != true) {
            seekNeuroReview = true;
        }
        else if(data['totalAWPTA'] != undefined && data['totalAWPTA'] != null && data['totalAWPTA'] > 0 && data['totalAWPTA'] < 18) {
            seekNeuroReview = true;
        }
        else if(data['timeInPTA'] != null && data['timeInPTA'] != undefined && data['timeInPTA' >= 4]) {
            seekNeuroReview = true;
        }

        if(seekNeuroReview) {
            let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'seekNeuroscienceReview', null, null, 0, false, false, Date.now(), null);

            let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'seekNeuroscienceReview', oldActions);
            if (reconsiledAction != null) {
                requiredActions.push(reconsiledAction);
            }
        }

        let physicalScore = [data['headacheBistRating'],
            data['neckHurtsBistRating'],
            data['brightLightsBistRating'],
            data['loudNoisesBistRating']];

        let vestibScore = [data['dizzySickBistRating'],
            data['closedEyesAtSeaBistRating'],
            data['troubleWithVisionBistRating'],
            data['clumsyBistRating']];

        let cognitiveScore = [data['longerToThinkBistRating'],
            data['forgetThingsBistRating'],
            data['confusedEasilyBistRating'],
            data['troubleConcentratingBistRating']];

        if (!physicalScore.includes(null) && !physicalScore.includes(undefined) && !vestibScore.includes(null) && !vestibScore.includes(undefined) && !cognitiveScore.includes(null) && !cognitiveScore.includes(undefined)) {
            
            if(data['ewsCriteriaRedflag'] == true || (data['totalGCS'] != undefined && data['totalGCS'] != null && data['totalGCS'] > 0 && data['totalGCS'] < 14)) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'seniorMedicalReview', null, null, 0, false, false, Date.now(), null);
    
                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'seniorMedicalReview', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            }
        }
        
        // Discharge Plan - Referral
        const patientScore = data['patientCalculatedScore'];
        const enableReferral = data['emergencyDepartmentReferral'];
        const highScoreVestibular =  workflow.rawData['highScoreVestibular'];
        
        if(enableReferral)
        {
            let actionName = "";
            if(patientScore > 36.1) {
                actionName = "accReferralModerateRiskAction";
            } else if (highScoreVestibular) {
                actionName = "physioTherapistReferralAction";
            } else {
                actionName = "accReferralLowRiskAction";
            }
            let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, actionName, null, null, 0, false, false, Date.now(), null);
            let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, actionName, oldActions);
            if (reconsiledAction != null) {
                requiredActions.push(reconsiledAction);
            }
        }

        var timeInPTA = data['timeInPTA'];

        if(data['ctCriteraRedflag'] == true || (timeInPTA != null && timeInPTA != undefined && timeInPTA >= 4 && data['totalAWPTA'] != null && data['totalAWPTA'] != undefined && data['totalAWPTA'] < 18)) {
            let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'ctHead', null, null, 0, false, false, Date.now(), null);

            let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'ctHead', oldActions);
            if (reconsiledAction != null) {
                requiredActions.push(reconsiledAction);
            }
        }
        
        //concussion diagnosis
        if ((data['concussiveForce'] == true || data['dangerousMechanismOfOInjury'] == true) && data['alterationInMentalState'] == true) {
            if(data['confoundingFactors'] == true) {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'diagnosisConcussionConfoudningFactorsAction', null, null, 0, false, false, Date.now(), null);

                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'diagnosisConcussionConfoudningFactorsAction', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            }
            else {
                let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'diagnosisConcussionAction', null, null, 0, false, false, Date.now(), null);

                let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'diagnosisConcussionAction', oldActions);
                if (reconsiledAction != null) {
                    requiredActions.push(reconsiledAction);
                }
            }
        }
        else {
            let action = new ActionModel(workflow.pathwayUuid, workflow.versionUuid, 'diagnosisConcussionNotConfirmedAction', null, null, 0, false, false, Date.now(), null);

            let reconsiledAction = checkAndUpdateForAction(pathwayId, action != null && patientEligable, action, 'diagnosisConcussionNotConfirmedAction', oldActions);
            if (reconsiledAction != null) {
                requiredActions.push(reconsiledAction);
            }
        }

        return requiredActions;
    }

    getAutoAcceptedValues() {
        return [];
    }

    getLastAWPTADate(rawData) {

        var checks = [
            'awptaCheck5DateTime',
            'awptaCheck4DateTime',
            'awptaCheck3DateTime',
            'awptaCheck2DateTime'];

        var lastCheckDate;

        for(let check of checks) {
            let date = rawData[check];
            if (date != null && date != undefined) {
                lastCheckDate = date;
                break;
            }
        }

        return lastCheckDate;
    }
}