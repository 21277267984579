import React, { createContext, ReactNode, useContext } from "react";
import { UserInfoReturn, useUserInfo } from "../Hooks/useUserInfo";

interface UserInfoProviderProp {
  children: ReactNode;
}

const UserInfoContext = createContext<UserInfoReturn | undefined>(undefined);

// Provider component
export const UserInfoProvider: React.FC<UserInfoProviderProp> = ({ children }) => {
  const store = useUserInfo();
  return <UserInfoContext.Provider value={store}>{children}</UserInfoContext.Provider>;
};

export const useUserInfoContext = (): UserInfoReturn => {
  const context = useContext(UserInfoContext);
  if (!context) {
    throw new Error("useUserInfoContext must be used within an UserInfoProvider");
  }
  return context;
};
