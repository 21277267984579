import React, { useState } from "react";
import { Box } from "@mui/material";
import { FormButton } from "../../FormsUI/FormsUI Components/Controls/FormButton";
import { FormDetail } from "../../Form/FormDetail";
import { updateActions } from "../../../components/state/actions/ActionActions";
import redux from "../../../components/state/stores/AppStore";

interface ReferralRecommendationFormDetailProp {
  formDetail: FormDetail;
  rawData: { [key: string]: any };
  inProgress: boolean;
}

const ReferralRecommendationFormDetail: React.FC<ReferralRecommendationFormDetailProp> = (
  props
) => {
  const [selectionValue, setSelectionValue] = useState<string>(
    props.rawData["referralToEDSelection"]
  );
  const patientScore = props.rawData["patientCalculatedScore"] as number;
  const clusters = props.rawData["calculatedSymptomCluster"] as string;
  const dominantVestibularCluster = clusters?.includes("Vestibularocular");
  const highScoreVestibular = props.rawData["highScoreVestibular"] as boolean;
  const pathwayId = "accbist01";

  const onSelectReferral = (selection: string) => {
    props.rawData["referralToEDSelection"] = selection;
    // Update actions to update "Summary - Discharge Plan" by this selection
    redux.store.dispatch(updateActions(pathwayId, redux.store.getState().workflowReducer?.workflows[pathwayId], props.formDetail.form));
    setSelectionValue(selection);
  };

  const getRecommendationText = () => {
    if (patientScore > 36.1) {
      return "Provide reassurance and education. Consider referral to concussion service as risk factors for prolonged recovery are indicated.";
    } else if (dominantVestibularCluster || highScoreVestibular) {
      return "Provide reassurance and education and consider referral to vestibular physiotherapist where available.";
    } else {
      return "Provide reassurance and education. Provide follow up and review in primary care in 7-10 days.";
    }
  };

  if (props.inProgress) {
    // Hide when the form is uncompleted
    return <Box></Box>;
  } else {
    return (
      <Box sx={{ mb: "0.6em" }}>
        <Box className="active-indicator">
          <Box>Referral to the Emergency Department</Box>
          <Box sx={{ color: "rgb(176, 176, 176)", fontSize: "0.9em", mt: 1, mb: 2 }}>
            Consider referral to the Emergency Department, if appropriate (e.g. based on time since
            injury).
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ ml: 2, fontSize: "15px" }}>
              Do you feel a referral to the emergency department is appropriate?
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", width: "220px" }}>
              <FormButton
                key="yes"
                onPress={() => onSelectReferral("yes")}
                enabled={true}
                isSelected={selectionValue === "yes"}
                title={"Yes"}
                ignoreTab={true}
              />
              <FormButton
                key="no"
                onPress={() => onSelectReferral("no")}
                enabled={true}
                isSelected={selectionValue === "no"}
                title={"No"}
                ignoreTab={true}
              />
            </Box>
          </Box>
          {selectionValue === "no" && (
            <Box sx={{ color: "orange", fontSize: "15px", mt: 2, ml: 2 }}>
              {getRecommendationText()}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
};

export default ReferralRecommendationFormDetail;
