import React from "react";
import { FormSummaryPage } from "./FormSummaryPage";
import { FormLoader } from "../../forms/FormLoader";
import { useUserInfoContext } from "../Hooks/UserInfoProvider";

interface FormSummaryPageWrapperProp {
  formLoader: FormLoader;
}

export const FormSummaryPageWrapper: React.FC<FormSummaryPageWrapperProp> = ({ formLoader }) => {
  const { userInfo } = useUserInfoContext();
  return (
    <FormSummaryPage
      formLoader={formLoader}
      userName={userInfo?.Name}
      userEmail={userInfo?.Email}
    />
  );
};
