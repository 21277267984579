export class BISTPatientScoreCalculator {
  score: number;

  constructor() {
    this.score = 0.0;
  }

  addCalculation(name: string, value: any) {
    switch (name) {
      case "patientAge":
        const age = value as number;
        if (age >= 65 || age <= 12) {
          this.score += 2.15;
        } else if (age <= 24) {
          this.score += 1.08;
        }
        break;
      case "confoundingFactors":
        const confoundingFactor = value as boolean;
        if (confoundingFactor) {
          this.score += 7.53;
        }
        break;
      case "numberOfVomittingEpisodes":
        const episodes = value as number;
        if (episodes > 1) {
          this.score += 3.23;
        }
        break;
      case "alterationInMentalState":
        const alteration = value as boolean;
        if (alteration) {
          this.score += 3.23;
        }
        break;
      case "feelingBetterSameWorseSinceInjury":
        const feeling = value as string;
        if (feeling === "sameOption") {
          this.score += 9.68;
        } else if (feeling === "worseOption") {
          this.score += 17.2;
        }
        break;
      case "numberOfPreviousConcussionsOrBrainInjuries":
        const number = value as number;
        if (number > 1) {
          this.score += 4.3;
        }
        break;
      case "protractedRecoveryDurationInMonths":
        const months = value as number;
        if (months >= 3) {
          this.score += 8.6;
        }
        break;
      case "mentalHealthDifficulties":
        const mental = value as boolean;
        if (mental) {
          this.score += 4.3;
        }
        break;
      case "historyOfMigraine":
        const historyOfMigraine = value as boolean;
        if (historyOfMigraine) {
          this.score += 5.38;
        }
        break;
      case "dominantVestibularCluster":
        const vestibular = value as boolean;
        if (vestibular) {
          this.score += 6.45;
        }
        break;
      case "calculatedSymptomScore":
        const totalScore = value as number;
        if (totalScore >= 66) {
          this.score += 19.35;
        }
        break;
      case "overallImpactScore":
        const overallImpact = value as number;
        if (overallImpact >= 6) {
          this.score += 18.28;
        }
        break;
      default:
        throw new Error(`${name} is not implemented.`);
    }
  }

  getScore() {
    return this.score > 100.0 ? 100.0 : this.score;
  }
}
