
import { PathwayReferenceHelper } from './PathwayReferenceHelper';
import { FormDetail } from '../waferJS/Form/FormDetail';
import { FormsContentType } from '../waferJS/FormsUI/FormsUI';
import { saveWorkflow } from '../components/state/actions/WorkflowResponseActions';
import { updateCalculations } from '../components/state/actions/CalculationActions';
import { updateActions } from '../components/state/actions/ActionActions';
import { redux } from '../App';
import { FormValidation } from '../waferJS/Form/Validation/FormValidation';
import { Form } from '../waferJS/Form/Form';
import { ReferenceDataLoader } from './ReferenceDataLoader';
import { getPathwayIdFromURL } from '../helpers/URLHelper';
import { FormValidationError } from '../waferJS/Form/Validation/FormValidationError';

import React from 'react';

export class FormWithRedux extends Form {

    get pathwayId() {
        return getPathwayIdFromURL();
    }

    set workflow(value) {
        this._workflow = JSON.parse(JSON.stringify(value));
    }

    get workflow() {
        return this._workflow;
    }

    get pathwayReferenceData(){
        return ReferenceDataLoader.referenceData(this.pathwayId);
    }    

    constructor(formIdentifier, formLoader, parentForm, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super(formIdentifier, formLoader);

        let self = this;
        redux.store.subscribe(() => {
            let state = redux.store.getState();
            self.workflow = state.workflowReducer.workflows;
        });

        this.parent = parentForm;
        this.actionUpdater = actionUpdater;
        this.dependentValuesUpdater = dependentValuesUpdater;
        this.calculationsUpdater = calculationsUpdater;

        let reference = PathwayReferenceHelper.reference(formIdentifier, this.pathwayReferenceData);
        this.title = reference[PathwayReferenceHelper.Type.title];
        this.detail = reference[PathwayReferenceHelper.Type.detail];
        this.sectionIds = reference[PathwayReferenceHelper.Type.subformSectionIds];
        this.info = reference[PathwayReferenceHelper.Type.info];

        this.workflow = redux.store.getState().workflowReducer.workflows;
        
        this.sectionMap = {};
        
        this.parseReference();
    }

    parseReference(){
        //Load all form detail identifiers from JSON
        if(this.sectionIds == undefined){ return; }
        for(let sectionId of this.sectionIds){
            let sectionRefernce = PathwayReferenceHelper.reference(sectionId, this.pathwayReferenceData);

            if(sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs] != null){
                for(let childID of sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs]){
                    this.formDetailIdentifiers.push(childID);
                    this.parseChildReference(childID);
                    this.sectionMap[childID] = sectionId;
                }
            }
        }
    }

    parseChildReference(childID){
        
        let reference = PathwayReferenceHelper.reference(childID, this.pathwayReferenceData);

        if(reference[PathwayReferenceHelper.Type.subcellIDs] != null){
            for (let child of reference[PathwayReferenceHelper.Type.subcellIDs]){
                this.formDetailIdentifiers.push(child);
                this.parseChildReference(child);
            }
        }

        if(reference.itemType == 'subform'){
            this.subformIdentifiers.push(childID);
        }
    }

    generateFormDetail(identifier) {
        let reference = PathwayReferenceHelper.reference(identifier, this.pathwayReferenceData);
        if (reference == null) {
            return null;
        }

        let options = [];
        if (reference[PathwayReferenceHelper.Type.selectionOptions] != null && reference[PathwayReferenceHelper.Type.selectionOptions] != undefined) {
            for (let i = 0; i < reference[PathwayReferenceHelper.Type.selectionOptions].length; i++) {
                let optionDetails = PathwayReferenceHelper.reference(reference[PathwayReferenceHelper.Type.selectionOptions][i], this.pathwayReferenceData);
                optionDetails[PathwayReferenceHelper.Type.identifier] = reference[PathwayReferenceHelper.Type.selectionOptions][i];
                options.push(optionDetails);
            }
        }

        let formDetail;
        let subtype = null;
        switch (reference.itemType) {
            case FormsContentType.ItemType.control:
                switch (reference[PathwayReferenceHelper.Type.controlType]) {
                    case FormsContentType.ControlType.textEntry:
                        subtype = reference[PathwayReferenceHelper.Type.textEntryType];
                        break;
                    case FormsContentType.ControlType.selection:
                        subtype = reference[PathwayReferenceHelper.Type.selectionType];
                        break;
                    case FormsContentType.ControlType.picker:
                        subtype = reference[PathwayReferenceHelper.Type.pickerType];
                        break;
                    default:
                        subtype = null;
                }
                formDetail = new FormDetail(this, identifier, reference.title, reference.detail, FormsContentType.ItemType.control, reference[PathwayReferenceHelper.Type.controlType],
                    subtype, options, reference[PathwayReferenceHelper.Type.subcellIDs] != null ? reference[PathwayReferenceHelper.Type.subcellIDs] : [],
                    reference[PathwayReferenceHelper.Type.textEntryUnitText], reference[PathwayReferenceHelper.Type.weblink], reference['date.format'], reference.info, reference.updateRendering);
                formDetail.initialValue = this.getValue(identifier);
                break;
            case FormsContentType.ItemType.subform:
                formDetail = new FormDetail(this, identifier, reference.title, reference.detail, FormsContentType.ItemType.subform, null, null, options, reference[PathwayReferenceHelper.Type.subcellIDs] != null ? reference[PathwayReferenceHelper.Type.subcellIDs] : [], null, null, null, reference.info, reference.updateRendering);
                break;
            default:
                formDetail = null;
        }


        return formDetail;
    }

    generateSubform() {
        return null;
    }

    generateSectionReference(identifier) {
        let reference = PathwayReferenceHelper.reference(identifier, this.pathwayReferenceData);
        this.generatedSectionReferences[identifier] = reference;
        return reference;
    }

    valueUpdated(identifier, value, forceSaveOption = false) {
        let changedDetail = this.formLoader.formDetail(this.pathwayId, identifier);

        if (!changedDetail) {
            console.log("Skipped to update value. Could not find form detail with identifier:" + identifier);
            return;
        }
        
        // Editing value should be updated IMMEDIATELY to reflect user input
        changedDetail.editingValue = value;

        let currentValue = changedDetail.currentValue;

        if (currentValue != changedDetail.initialValue) {
            if (currentValue == null || currentValue == undefined) {
                this.save({[identifier]: null}, this.workflow[this.pathwayId], true, 0);
                return; // Don't perform validity checks on null values
            }

            let allErrors = this.validationErrors(identifier, currentValue);
            let validityErrors = allErrors.filter(function (error) {
                return error.errorType == FormValidationError.FormErrorType.error;
            });
            
            if (validityErrors.length > 0 && !forceSaveOption) {
                // We save a null here to ensure dependent displayed items are updated rather than referring to the old value.
                // We don't update initialValue here because we don't want to trigger editingValue to update and purge the invalid data mid entry.
                this.save({[identifier]: null}, this.workflow[this.pathwayId], false, 0);
                return; // Force saving of null when data is invalid
            }

            this.save({[identifier]: currentValue}, this.workflow[this.pathwayId], true, 0);
        }
    }

    shouldDisplayFormDetailForIdentifier() {
        
    }

    isEnabled() {
        return true;
    }

    isMandatory() {
        return false;
    }

    getValueType() {
        throw new Error('Requires override');
    }

    requiredValidation() {
        return [FormValidation.notNill];
    }

    formDetailString(){
        return this.detail;
    }

    formInfoString(){
        return this.info;
    }

    getValue(identifier) {
        let value;
        if (this.workflow != undefined && this.workflow[this.pathwayId] != undefined && this.workflow[this.pathwayId].rawData != undefined) {
            value = this.workflow[this.pathwayId].rawData[identifier];
        }

        return value;
    }

    getBadgeValue() {
        return null;
    }

    save(valuesToSave, workflow, updateInitialValue, iterationIndex) {
        if (workflow != undefined) {
            // We should perform an update of initialValue to refer to the most recent valid value for this FormDetail
            // We don't perform this update if the editingValue is invalid (resulting in null)
            if (updateInitialValue == true) {
                Object.keys(valuesToSave).forEach(identifier => {
                    // Instead of formDetail which can generate the form detail, use only existing formDetail in this case
                    let value = valuesToSave[identifier];
                    
                    let formDetail = this.formDetail(identifier);
                    if (formDetail != null) {
                        formDetail.initialValue = value;
                    }
                });
            }

            redux.store.dispatch(saveWorkflow(this.pathwayId, valuesToSave, workflow, true, this, iterationIndex));

            // Is there a way to only perform this once all recursive save workflows have completed?
            redux.store.dispatch(updateCalculations(this.pathwayId, redux.store.getState().workflowReducer.workflows[this.pathwayId], redux.store.getState().calculationReducer.calculations, this));
            redux.store.dispatch(updateActions(this.pathwayId, redux.store.getState().workflowReducer.workflows[this.pathwayId], this));
        }
    }

    getPlainText(){
        let notSpecifiedSectionValues = [];

        let sectionStrings = this.sectionIds.map((sectionID) => {
            let section = this.sectionReference(sectionID);
            var sectionString = '';
            
            let sectionValues = [];
            let notSpecifiedWithinSectionValues = [];
            let notPresentWithinSectionValues = [];
            let sectionsToDisplay = [];
            if(section[PathwayReferenceHelper.Type.sectionChildIDs] != undefined){
                section[PathwayReferenceHelper.Type.sectionChildIDs].map((childID) => {
                    if(this.shouldDisplayFormDetailForIdentifier(childID)){
                        let formDetail = this.formDetail(childID);
                        let summaryValue = this.getSummaryValue(false, formDetail);
                        if(summaryValue == false){
                            notPresentWithinSectionValues.push(formDetail.title);
                        } else if(summaryValue != null){
                            sectionValues.push(summaryValue);
                        } else if(summaryValue == null && this.isMandatory(childID)){
                            notSpecifiedWithinSectionValues.push(formDetail.title);
                        }
                        sectionsToDisplay.push(sectionID);
                    } 
                });

                if(sectionValues.length > 0){
                    if(section.title != null){
                        sectionString += section.title + ': \n';
                    }
                    sectionString += sectionValues.join(', ');
                } else if(this.shouldShowEmptySection(sectionID) && sectionsToDisplay.includes(sectionID)) {
                    if(section.title != null){
                        sectionString += section.title + ': \n';
                    }
                    sectionString += 'None specified';
                    notSpecifiedSectionValues = notSpecifiedSectionValues.concat(notSpecifiedWithinSectionValues);
                }

                if(notPresentWithinSectionValues.length > 0 && this.shouldShowEmptySection(sectionID)){
                    sectionString += '\n\n(Not present: ' + notPresentWithinSectionValues.join(', ') + ')';
                }

                if(sectionValues.length > 0 && this.shouldShowEmptySection(sectionID) && sectionsToDisplay.includes(sectionID)){
                    if(notSpecifiedWithinSectionValues.length > 0){
                        sectionString += '\n\n[Not specified: ' + notSpecifiedWithinSectionValues.join(', ') + ']';
                    }
                }
            }

            return sectionString == '' ? null : sectionString;
        }).filter( n => n);

        let combinedSections = sectionStrings.join('\n\n');
        if(notSpecifiedSectionValues.length > 0){
            combinedSections += '\n\n[Not specified: ' + notSpecifiedSectionValues.join(', ') + ']';
        }

        return combinedSections;
    }

    shouldShowEmptySection(){
        return true;
    }

    getSummaryValue(isSubform, value){
        return value.valueDescription();
    }

    noToAll(identifier){
        let sectionId = this.sectionMap[identifier];
        let sectionRefernce = PathwayReferenceHelper.reference(sectionId, this.pathwayReferenceData);
        if(sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs] != null){
            var changedValues = {};
            for(let childID of sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs]){
                let formDetail = this.formDetail(childID);
                if(formDetail.controlType == FormsContentType.ControlType.selection && formDetail.controlSubtype == FormsContentType.SelectionType.explicit && this.getValue(formDetail.identifier) == null){
                    changedValues[childID] = false;
                }
            }
            this.save(changedValues, this.workflow[this.pathwayId], true, 0);
        }
    }

    unselectedExplicitFormDetailsCount(identifier){
        let count = 0;
        let sectionId = this.sectionMap[identifier];
        let sectionRefernce = PathwayReferenceHelper.reference(sectionId, this.pathwayReferenceData);
        if(sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs] != null){
            for(let childID of sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs]){
                let formDetail = this.formDetail(childID);
                if(formDetail.controlType == FormsContentType.ControlType.selection && formDetail.controlSubtype == FormsContentType.SelectionType.explicit){
                    if(this.getValue(formDetail.identifier) == null){
                        count += 1;
                    }
                }
            
            }
        }
        return count;
    }

    unselectedExplicitDetailString(identifier){
        let count = 0;
        let total = 0;
        let sectionId = this.sectionMap[identifier];
        let sectionRefernce = PathwayReferenceHelper.reference(sectionId, this.pathwayReferenceData);
        if(sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs] != null){
            for(let childID of sectionRefernce[PathwayReferenceHelper.Type.sectionChildIDs]){
                let formDetail = this.formDetail(childID);
                if(formDetail.controlType == FormsContentType.ControlType.selection && formDetail.controlSubtype == FormsContentType.SelectionType.explicit){
                    if(this.getValue(formDetail.identifier) == null){
                        count += 1;
                    }
                    total += 1;
                    
                }
            
            }
        }
        return count == total ? 'all' : count;
    }

    htmlForIdentifier(prefix, value) {
        if (this.subform(value.identifier) != null && this.subform(value.identifier) != undefined) {
            let subform = this.subform(value.identifier);
            let details = subform.detailString(true);
            if (details != null && details != undefined) {
                return (<div key={value.identifier} >
                    <label className='list-title'>{subform.title}</label>
                    {subform.detailHTML(true)}
                </div>);
            }
            else {
                return null;
            }
        }
        else if (!this.shouldDisplayFormDetailForIdentifier(value.identifier) || value.valueDescription() == null) {
            return null;
        }
        else if (value.valueDescription() == false) {
            return (<div key={value.identifier} >
                <label className='list-detail'>{value.title}: &#160;</label>
                <label className='list-detail'>No</label>
            </div>);
        }
        else if (value.valueDescription(false) == true) {
            return (<div key={value.identifier} >
                <label className='list-detail'>{value.title}: &#160;</label>
                <label className='list-detail'>Yes</label>
            </div>);
        }

        return (<div key={value.identifier} >
            {
                value.title != null &&
                <label className='list-detail'>{value.title}: &#160;</label>
            }
            <label className='list-detail'>{value.valueDescription()}</label>
        </div>);
    }

    detailHTML(isSubform) {
        let prefix = isSubform ? '- ': '';

        let formDetails = this.getFormDetails();
        let values = formDetails.map((value) => {
            return this.htmlForIdentifier(prefix, value);
        });

        if(values.filter(n => n).length == 0) {
            return null;
        }
        
        values = values.filter(n => n);

        return (
            <div key={this.identifier}>
                {values.map((value) => {
                    return (value);
                })}
            </div>
        );
    }

}