import { FormWithRedux } from '../../FormWithRedux';
import { FormValidationError } from '../../../waferJS/Form/Validation/FormValidationError';
import { FormValidation } from '../../../waferJS/Form/Validation/FormValidation';

import { ACCBISTMentalStateForm } from './ACCBIST-MentalStateForm';
import { ACCBISTConfoundingFactorsForm } from './ACCBIST-ConfoundingFactorsForm';
import { CheckGCSRange } from '../Validation Overrides/ACCBIST-PathwayValidation';
import { IsNumber } from '../../../waferJS/Form To FormsUI/Subclasses/Validation Overrides/DecimalNumberValidaton';

export class ACCBISTInjuryDetailsForm extends FormWithRedux {

    constructor(formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater) {
        super('injuryDetailsSubform', formLoader, parent, actionUpdater, dependentValuesUpdater, calculationsUpdater);
    }

    generateSubform(identifier) {
        switch (identifier) {
            case 'alterationInMentalStateSubform':
                return new ACCBISTMentalStateForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
            case 'confoundingFactorsSubform':
                return new ACCBISTConfoundingFactorsForm(this.formLoader, this, this.actionUpdater, this.dependentValuesUpdater, this.calculationsUpdater);
        }
    }

    shouldDisplayFormDetailForIdentifier(identifier) {
        super.shouldDisplayFormDetailForIdentifier(identifier);

        if (identifier == 'arrivalGCSDateTime') {
            return this.getValue('arrivalGCS') != null && this.getValue('arrivalGCS') != undefined;
        }
        else if (identifier == 'worstGCSDateTime') {
            return this.getValue('worstGCS') != null && this.getValue('worstGCS') != undefined;
        }
        else if (identifier == 'concussiveForce') {
            return this.getValue('dangerousMechanismOfOInjury') == false; 
        }
        // else if (identifier == 'directHeadInjury' || identifier == 'isolatedHeadInjury') {
        //     return this.getValue('concussiveForce') == true; 
        // }
        else if (identifier == 'otherInjuries') {
            return this.getValue('directHeadInjury') == false || (this.getValue('otherInjuries') != null && this.getValue('otherInjuries') != undefined); 
        }
        else if (identifier == 'alterationInMentalStateSubform') {
            let dependentValues = [this.getValue('lossOfConsciousness'), this.getValue('retrogradeAmnesia'), this.getValue('alteredMentalState')];
            let hasAnswers = dependentValues.includes(true) || dependentValues.includes(false);

            return this.getValue('alterationInMentalState') != false || hasAnswers;
        }
        else if (identifier == 'confoundingFactorsSubform') {
            let dependentValues = [this.getValue('intoxicationOrDrugUse'), this.getValue('psychologicalOrEmotionalTrauma'), this.getValue('severePain')];
            let hasAnswers = dependentValues.includes(true) || dependentValues.includes(false);
            
            return this.getValue('confoundingFactors') != false || hasAnswers;
        }
        else if (identifier == 'invalidArrivalGCSWarning') {
            return this.getValue('arrivalGCS') != null && this.getValue('arrivalGCS') != undefined && this.getValue('arrivalGCS') <= 13;
        }
        else {
            return true;
        }

    }

    isEnabled() {
        return true;
    }

    isMandatory(identifier) {
        if (identifier == 'worstGCS' || identifier == 'arrivalGCS') {
            return false;
        }
        else if (identifier == 'arrivalGCSDateTime') {
            return this.getValue('arrivalGCS') != null && this.getValue('arrivalGCS') != undefined;
        }
        else if (identifier == 'worstGCSDateTime') {
            return this.getValue('worstGCS') != null && this.getValue('worstGCS') != undefined;
        }
        else if (identifier == 'isolatedHeadInjury') {
            return this.getValue('directHeadInjury') == true; 
        }
        else if (identifier == 'otherInjuries') {
            return this.getValue('directHeadInjury') == false; 
        }
        else if (identifier == 'additionalInjuryDetails') {
            return false;
        }
        else if (identifier == 'concussiveForce') {
            return this.getValue('dangerousMechanismOfOInjury') == false;
        }
        else if (identifier == 'invalidArrivalGCSWarning') {
            return false;
        }

        return true;
    }

    getValueType(identifier) {
        if (identifier == 'injuryOccuranceDateTime' || identifier == 'worstGCSDateTime' || identifier == 'arrivalGCSDateTime') {
            return 'date';
        } else if (identifier == 'injuryEventDescription' 
        || identifier == 'additionalInjuryDetails' 
        || identifier == 'otherInjuries') {
            return 'string';
        }
        else if(identifier == 'worstGCS' 
        || identifier == 'arrivalGCS' 
        || identifier == 'lossOfConsciousnessDuration'
        || identifier == 'retrogradeAmnesiaDuration') {
            return 'double';
        }
        else if(identifier == 'dangerousMechanismOfOInjury' 
        || identifier == 'concussiveForce'
        || identifier == 'directHeadInjury'
        || identifier == 'alterationInMentalState'
        || identifier == 'postTraumaticSeizure'
        || identifier == 'confoundingFactors'
        || identifier == 'isolatedHeadInjury'
        || identifier == 'lossOfConsciousness'
        || identifier == 'retrogradeAmnesia'
        || identifier == 'alteredMentalState'
        || identifier == 'intoxicationOrDrugUse'
        || identifier == 'psychologicalOrEmotionalTrauma'
        || identifier == 'severePain') {
            return 'boolean';
        }

        return null;
    }

    submissionValidation(identifier) {
        if(identifier == 'alterationInMentalState'){
            let self = this;
            let criteriaConflict = new FormValidation('AlterationInMentalStateConflict', function () {
                let value = self.getValue('alterationInMentalState');

                let allBooleanValues = [self.getValue('lossOfConsciousness'), self.getValue('retrogradeAmnesia'), self.getValue('alteredMentalState')];
                if (value == false) {
                    if (allBooleanValues.includes(true)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                } else if (value == true){
                    if (!allBooleanValues.includes(true) && !allBooleanValues.includes(null) && !allBooleanValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        else if (identifier == 'confoundingFactors') {
            let self = this;
            let criteriaConflict = new FormValidation('AlterationInMentalStateConflict', function () {
                let value = self.getValue('confoundingFactors');

                let allBooleanValues = [self.getValue('intoxicationOrDrugUse'), self.getValue('psychologicalOrEmotionalTrauma'), self.getValue('severePain')];
                if (value == false) {
                    if (allBooleanValues.includes(true)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                } else if (value == true) {
                    if (!allBooleanValues.includes(true) && !allBooleanValues.includes(null) && !allBooleanValues.includes(undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                }
                return [];
            });
                
            return [criteriaConflict];
        }
        else if (identifier == 'directHeadInjury') {
            let self = this;
            let criteriaConflict = new FormValidation('DirectHeadInjuryConflict', function () {
                let value = self.getValue('directHeadInjury');

                if (value == true) {
                    if (self.getValue('isolatedHeadInjury') == true || (self.getValue('otherInjuries') != null && self.getValue('otherInjuries') != undefined)) {
                        return [new FormValidationError(FormValidationError.FormErrorType.error, 'Modify to match selection')];
                    }
                } 

                return [];
            });
                
            return [criteriaConflict];
        }
    }

    editingValidation(identifier) {
        if (identifier == 'worstGCS') {
            return [IsNumber, CheckGCSRange];
        } else if (identifier == 'arrivalGCS') {
            return [IsNumber, CheckGCSRange];
        }
        return [];
    }
}