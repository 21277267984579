import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import getUserInfoFromB2CServer, { UserInfo } from "../../api/getUserInfo";

export interface UserInfoReturn {
  instance: IPublicClientApplication;
  loading: boolean;
  isLowAccessLevel: boolean;
  userInfo: UserInfo | undefined;
}

export function useUserInfo(): UserInfoReturn {
  const [loading, setLoading] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isLowAccessLevel, setIsLowAccessLevel] = useState<boolean>(false);

  const { instance } = useMsal();

  useEffect(() => {
    if (instance && instance.getAllAccounts().length > 0) {
      setLoading(true);
      getUserInfoFromB2CServer((data, error) => {
        if (error) {
          console.log("Failed to get user info. Starting logout. Error:" + error);
          setLoading(false);
          instance.logoutRedirect();
        } else if (data)
          if (data && (!data.ConfidenceLevel || data.ConfidenceLevel <= 1 || !data.HPINumber)) {
            // Display the update account button
            setIsLowAccessLevel(true);
          } else {
            setUserInfo(data);
          }
        setLoading(false);
      });
    }
  }, [instance]);

  return { instance, loading, userInfo, isLowAccessLevel };
}
