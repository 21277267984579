import { FormDetailGenerator } from './FormDetailGenerator';
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '../FormsUI/FormsUI Components/Controls/FormLabel';
import { FormValidationError } from '../Form/Validation/FormValidationError';

export class FormDetailWithReact extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            formDetail: props.formDetail,
            errorLabels: [],
            hasError: false,
        };

        this.renderSubcells = this.renderSubcells.bind(this);
        this.renderErrors = this.renderErrors.bind(this);
    }

    componentDidMount() {
        this.renderErrors();
    }

    componentDidUpdate() {
        if (this.props.formDetail.updateRendering === true) {
            this.renderErrors();
        }
    }

    renderSubcells() {
        let elements = [];
        for (let subcellIDIndex in this.props.formDetail.subcellIDs) {
            let subcellID = this.props.formDetail.subcellIDs[subcellIDIndex];
            if (this.props.formDetail.form.shouldDisplayFormDetailForIdentifier(subcellID)) {
                let formDetail = this.props.formDetail.form.formDetail(subcellID);
                if (formDetail != null) {
                    elements.push(<FormDetailWithReact formLoader={this.props.formLoader} key={subcellID} formDetail={formDetail} rawValue={this.props.rawValue} isSummary={false} isSubcell={true} showHover={this.props.formDetail.subcellIDs.length > 1}/>);
                }
            }
        }

        return elements;
    }

    renderErrors(additionalErrors) {
        let errors = this.props.formDetail.form.validationErrors(this.props.formDetail.identifier, this.props.formDetail.currentValue);

        let validationsToShow = errors.filter(function (error) {
            return error != null && error.errorType != FormValidationError.FormErrorType.required;
        });

        let errorLabels = [];
        let hasError = false;
        for (let index in validationsToShow) {
            let error = validationsToShow[index];

            if(error.errorType == FormValidationError.FormErrorType.error){
                hasError = true;
            }

            errorLabels.push(<FormLabel key={index.toString() + error.message} value={error.message} textColor={error.color} fontSize={0.9} />);
        }

        if(additionalErrors != null && additionalErrors.length > 0){
            errorLabels = errorLabels.concat(additionalErrors);
        }

        if(this.state.hasError != hasError){
            this.setState({hasError: hasError});
        }

        if(this.state.errorLabels != null && !this.arraysEqual(this.state.errorLabels, errorLabels)){
            this.setState({ errorLabels: errorLabels });
        } else if(this.state.errorLabels == null || this.state.errorLabels == undefined) {
            this.setState({ errorLabels: errorLabels });
        }
    }

    arraysEqual(arr1, arr2) {
        if(arr1.length !== arr2.length){
            return false;
        }

        for(var i = arr1.length; i--;) {
            if (arr2.filter((item) => {return item.props.value == arr1[i].props.value && item.props.textColor == arr1[i].props.textColor; }).length == 0) {
                return false;
            }
        }
    
        return true;
    }

    render() {
        this.renderSubcells();
        return <FormDetailGenerator formLoader={this.props.formLoader} key={this.props.formDetail.identifier} renderSubcells={this.renderSubcells} renderErrors={this.renderErrors} rawValue={this.props.rawValue} formDetail={this.props.formDetail} errorLabels={this.state.errorLabels} hasError={this.state.hasError} isSummary={this.props.isSummary} isSubcell={this.props.isSubcell} showHover={this.props.showHover} />;
    }
}

FormDetailWithReact.propTypes = {
    formDetail: PropTypes.object.isRequired,
    isSummary: PropTypes.bool.isRequired,
    isSubcell: PropTypes.bool.isRequired,
    showHover: PropTypes.bool.isRequired,
    formLoader: PropTypes.object.isRequired,
    rawValue: PropTypes.any,
    workflows: PropTypes.object,
};